.jacquard-24-regular {
  font-family: "Jacquard 24", system-ui;
  font-weight: 400;
  font-style: normal;
}

.fantasy{
  font-family: 'fantasy';
}

.birthstone-bounce-regular {
  font-family: "Birthstone Bounce", cursive;
  font-weight: 400;
  font-style: normal;
}

.birthstone-bounce-medium {
  font-family: "Birthstone Bounce", cursive;
  font-weight: 500;
  font-style: normal;
}

.danfo{
  font-family: 'Danfo'
}
*{
  font-size: large;
  color: white;
}

body{
  overflow: hidden;
  background: black;
}

.welcome {
  background: linear-gradient(15deg, rgb(255, 148, 189) 35%, rgba(12,18,51,1) 38%, rgba(0,0,0,1) 55%);
  width: 100vw;
  height: 100vh;
}

header{
  padding-top: 1.5em;
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

#navMenu{
  display: flex;
  margin-right: 3em;
}

@media only screen and (min-device-width: 600px){
  iframe {
    display: inline !important;
  }
  .navItem a{
    font-size: large !important;
  }
}

.navItem a{
  font-size: 48px;
}

.navItem{
  margin-top: 3em;
  margin-right: 3em;
  text-decoration: none;
  font-size: 12px;
}

a{
  text-decoration: none;
}

#navName{
  font-size: small;
  padding-left: 3em;
  color: rgba(255, 255, 255, 0.3);
  border-bottom: 2px solid red;
  height: 17px;
}

.welcomeDiv{
  font-size: 128px;
  padding-top: 9%;
  padding-left: 20%;
  line-height: 130px;
}

iframe {
  display: none;
}

@media only screen and (min-width: 600px) {
  .welcomeDiv{
    font-size: 128px;
    padding-top: 9%;
    padding-left: 55%;
    line-height: 130px;
  }
}

.projectsDiv a{
  color: cyan;
  text-decoration: underline;
}